<template>
  <v-container fluid>
    <router-view />
    <FilterDrawer
      current-component="crm-filter"
      @filter="filterCrm"
      @clear="clearFilter"
    />

    <Table :data="dataTable">
      <template v-slot:tbody="{ item }">
        <td>{{ item.title }}</td>
        <td>
          <ul class="pa-0">
            <li>{{ item.client.company_name }}</li>
            <li>{{ item.client.trading_name }}</li>
          </ul>
        </td>
        <td>
          <ContactInfo
            :name="JSON.parse(item.client.client_contact)[0].name"
            :phone-one="JSON.parse(item.client.client_contact)[0].phone_number"
            :phone-two="JSON.parse(item.client.client_contact)[0].cel_number"
            :email="JSON.parse(item.client.client_contact)[0].email"
          />
        </td>
        <td>{{ item.seller.name }}</td>
        <td>
          <StatusChip
            :status="priorityLevelStatus"
            :current-status="item.priority_level"
          />
        </td>
        <td class="text-center">
          <div>{{ item.send_date | formatDate }}</div>
          <div>{{ item.send_date | formatTime }}</div>
        </td>
        <td class="text-center">
          {{ item.return_date | formatDate }}
        </td>
        <td>
          <v-chip
            class="white--text"
            :color="
              checkSituation(item.item) ? 'yellow darken-2' : 'blue lighten-1'
            "
          >
            {{ checkSituation(item.item) ? 'Em aberto' : 'Em andamento' }}
          </v-chip>
        </td>
        <td>{{ item.created_at }}</td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import ContactInfo from '@/components/data-table/ContactInfo'
import StatusChip from '@/components/data-table/StatusChip'
import FilterDrawer from '@/components/FilterDrawer'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    ContactInfo,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    priorityLevelStatus: [
      { value: 1, text: 'Baixa', color: 'blue' },
      { value: 2, text: 'Média', color: 'warning' },
      { value: 3, text: 'Alta', color: 'error' },
    ],
    dataTable: {
      title: 'Atividades CRM',
      new: 'crm-new',
      edit: 'crm-edit',
      context: 'crm-context',
      massAction: 'crm-mass-action',
      headerButtons: {
        filter: true,
        new: false,
        newMenu: false,
      },
      items: [],
      isLoading: false,
      headers: [
        { text: 'Título', value: 'title' },
        { text: 'Empresa', value: 'crm_company' },
        { text: 'Contato', value: 'crm_client_contact' },
        { text: 'Vendedor' },
        {
          text: 'Prioridade',
          value: 'crm_priority_level',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Último contato',
          value: 'send_date',
          width: 200,
          align: 'center',
        },
        {
          text: 'Próximo contato',
          value: 'return_date',
          width: 200,
          align: 'center',
        },
        { text: 'Alerta', value: 'is_open', align: 'center' },
        { text: 'Data de criação', value: 'created_at', align: 'end' },
      ],
    },
  }),
  created() {
    this.getCrm()

    contextMenu.$once('action', (type) => {
      if (type === 'crms') {
        this.getCrm()
      }
    })
    massAction.$once('action', () => {
      this.getCrm()
    })
  },
  methods: {
    getCrm() {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('crms')
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterCrm(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('crms/filter', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getCrm()
    },
    checkSituation(item) {
      if (item) {
        const today = new Date(Date.now())
        const return_date = new Date(item.return_date)

        if (today > return_date) {
          return true
        } else {
          return false
        }
      }
    },
  },
}
</script>
